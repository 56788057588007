import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import SpacingExample from '../../../../components/examples/SpacingExample';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/spacing/tokens",
  "title": "Spacing tokens - Tokens"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Tokens`}<a parentName="h2" {...{
        "href": "#tokens",
        "aria-label": "tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`REM value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-4-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-4-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0.125rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-4-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-3-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-3-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0.24rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-3-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-2-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-2-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-2-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`12px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`0.75rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-s)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-m)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-l)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`40px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-2-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-2-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-2-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-3-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-3-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`56px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-3-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-4-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-4-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-4-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-5-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-5-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`72px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-5-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Spacing tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`CSS variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`SASS variable`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`PX value`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`REM value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-2-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-2-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`16px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-2-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-xs`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`24px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1.5rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-xs)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-s`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`32px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`2rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-s)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-m`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`48px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`3rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-m)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`64px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`4rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-l)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`96px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`6rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--spacing-layout-2-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-layout-2-xl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`128px`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`8rem`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><SpacingExample size="var(--spacing-layout-2-xl)" mdxType="SpacingExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Layout tokens]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      